@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply border border-black rounded-lg px-4 py-2 dark:border-white text-sm text-gray-900 dark:text-white cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white 
        
  }
  .wrapper {
    @apply bg-custom-light dark:bg-custom-dark p-custom-wrapper-mobile md:p-custom-wrapper h-auto w-full
  }

  .h1-title {
    @apply text-center py-5 text-3xl md:text-5xl font-bold uppercase text-blue-700
  }

  .h2-title {
    @apply text-left pt-5 text-xl font-bold uppercase text-blue-700 dark:text-blue-500
  }

  .contact-input {
    @apply bg-white dark:bg-black text-black dark:text-white my-3 w-full
  }


}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}

