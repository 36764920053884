.App {
    width: 100%;
    height: 100%;
}

@keyframes ring-ring {
    0% {
      transform: rotate(0);
    }
    1.5% {
      transform: rotate(40deg);
    }
    3% {
      transform: rotate(-40deg);
    }
    4.5% {
      transform: rotate(30deg);
    }
    6% {
      transform: rotate(-30deg);
    }
    7.5% {
      transform: rotate(20deg);
    }
    9% {
      transform: rotate(-20deg);
    }
    10.5% {
      transform: rotate(10deg);
    }
    12% {
      transform: rotate(-10deg);
    }
    13.5% {
      transform: rotate(0);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }
    70% {
      box-shadow: 0 0 0 30px rgba(255, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }

  .ringing-phone {
    animation: ring-ring 1.5s linear infinite;
  }

  .pulse-circle {
    position: relative;
  }
  
  .pulse-circle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
    animation: pulse 1.8s infinite;
  }